import { get, post, put, Delete } from "../axios";

// 盘厂列表-列表
export const factoryManageList = (params) =>
  get("/web/factory/manage/list", params);

// 盘厂列表-新增
export const factoryManageAdd = (params) =>
  post("/web/factory/manage/add", params);

// 盘厂列表-编辑
export const factoryManageUpdate = (params) =>
  put("/web/factory/manage/update", params);

// 盘厂列表-删除
export const factoryManageDelete = (id) =>
  Delete("/web/factory/manage/delete/" + id, "");

// 盘厂列表-权益列表（用于新增和编辑）
export const factoryManageEquityClassifyList = (params) =>
  get("/web/factory/manage/equity/classify/list", params);

// 盘厂列表-详情
export const factoryManageDetail = (id) =>
  get("/web/factory/manage/view/" + id, "");

// 盘厂列表-首页推荐-状态编辑
export const factoryManageHomeUpdate = (params) =>
  put("/web/factory/manage/home/update", params);
