<template>
  <div class="main-content">
    <avue-crud
      :data="tableData"
      :option="option"
      :table-loading="showLoading"
      :page.sync="page"
      @on-load="onLoad"
    >
      <template #header>
        <div>
          <div class="flex flex-wrap align-items marginBottom20">
            <div class="flex align-items marginRight10 marginBottom10">
              <span class="span marginRight10 width70 textAlignRight"
                >盘厂名称 :
              </span>
              <div class="width300">
                <el-input
                  v-model="searchData.name"
                  placeholder="请输入"
                  clearable
                  maxlength="50"
                ></el-input>
              </div>
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <el-button size="medium" type="primary" @click="handleSearch"
                >搜索</el-button
              >
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <el-button size="medium" type="info" @click="handleReset"
                >重置</el-button
              >
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <el-button
                size="medium"
                type="primary"
                @click="handleCreate('create')"
                >新增</el-button
              >
            </div>
          </div>
        </div>
      </template>
      <template slot="isHome" slot-scope="{ row }">
        <el-switch
          :value="row.isHome"
          :inactive-value="2"
          :active-value="1"
          @change="handleSwitch($event, row.id)"
        ></el-switch>
      </template>
      <template slot="image" slot-scope="{ row }">
        <el-image
          v-if="row.image"
          style="width: 80px; height: 80px"
          :src="row.image"
          :preview-src-list="[row.image]"
        >
        </el-image>
      </template>
      <template slot="state" slot-scope="{ row }">
        <el-switch
          :value="row.state"
          :inactive-value="2"
          :active-value="1"
          @change="handleSwitch($event, row.id)"
        ></el-switch>
      </template>
      <template slot="menu" slot-scope="{ row }">
        <el-button type="text" size="mini" @click="handleCreate('view', row.id)"
          >详情</el-button
        >
        <el-button
          type="text"
          size="mini"
          @click="handleCreate('update', row.id)"
          >编辑</el-button
        >
        <el-button
          type="text"
          size="mini"
          @click="handleDelete(row)"
          style="color: #ff001f !important"
          >删除</el-button
        >
      </template>
    </avue-crud>
    <!-- <el-dialog
      v-if="dialogVisible"
      :title="`${form.id ? '修改盘厂' : '新增盘厂'}`"
      :visible.sync="dialogVisible"
      width="40%"
      :before-close="handleClose"
      :close-on-click-modal="false"
      style="padding-right: 10%"
    >
      <avue-form ref="form" v-model="form" :option="formOption">
        <template slot="name">
          <el-input
            v-model="form.name"
            maxlength="20"
            placeholder="请输入"
            onkeyup="this.value=this.value"
            @blur="salaryDianChange($event, 'name')"
            type="text"
            clearable
            :disabled="isDetails"
          />
        </template>
        <template slot="image">
          <FormImgUpload
            :url="form.image"
            @upload="upload"
            :limit="1"
            :maxSize="20"
            :accept="'.png,.jpg,.jpeg,.svg,.tiff'"
            :multiple="true"
          />
    
          <div style="color: #c0c1c3">
            建议尺寸：96px*96px，图片格式：.png,.jpg,.jpeg,.svg,.tiff
          </div>
        </template>
        <template slot="equityIds">
          <el-select
            v-model="form.equityIds"
            clearable
            style="width: 400px"
            :disabled="isDetails"
            :multiple="true"
          >
            <el-option
              v-for="item in classifyList"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            ></el-option>
          </el-select>
        </template>
        <template slot="cooperate">
          <el-input
            v-model="form.cooperate"
            maxlength="20"
            placeholder="请输入"
            onkeyup="this.value=this.value"
            @blur="salaryDianChange($event, 'cooperate')"
            type="text"
            clearable
            :disabled="isDetails"
          />
        </template>
        <template slot="phone">
          <el-input
            v-model="form.phone"
            maxlength="11"
            placeholder="请输入"
            onkeyup="this.value=this.value"
            @blur="salaryDianChange($event, 'phone')"
            type="text"
            clearable
            :disabled="isDetails"
          />
        </template>
        <template slot="power">
          <el-input
            v-model="form.power"
            maxlength="20"
            placeholder="请输入"
            onkeyup="this.value=this.value"
            @blur="salaryDianChange($event, 'power')"
            type="text"
            clearable
            :disabled="isDetails"
          />
        </template>
        <template slot="address">
          <el-input
            v-model="form.address"
            maxlength="10"
            placeholder="请输入"
            onkeyup="this.value=this.value"
            @blur="salaryDianChange($event, 'address')"
            type="text"
            clearable
            :disabled="isDetails"
          />
        </template>

        <template slot="menuForm">
          <el-button @click="handleClose">
            {{ isDetails ? "关闭" : "取消" }}</el-button
          >
          <el-button
            type="primary"
            @click="submit(form)"
            :loading="showLoadingForm"
            >确定</el-button
          >
        </template>
      </avue-form>
    </el-dialog> -->
    <FactoryForm
      ref="formRef"
      @success="handleSearch"
      :classifyList="classifyList"
    />
  </div>
</template>

<script>
import {
  factoryManageList,
  factoryManageAdd,
  factoryManageUpdate,
  factoryManageDelete,
  factoryManageEquityClassifyList,
  factoryManageDetail,
  factoryManageHomeUpdate,
} from "@/api/factory/manage";
import { mapGetters } from "vuex";
import { mobilePhoneRule } from "@/utils/formRules";
import FormImgUpload from "@/components/formComponents/formImgUpload";
import FactoryForm from "./FactoryForm.vue";

export default {
  name: "index",
  components: {
    FormImgUpload,
    FactoryForm,
  },
  // computed: {
  // 	...mapGetters(['getButton'])
  // },
  data() {
    return {
      button: {},
      isDetails: false,
      searchData: {
        name: "",
      },
      page: {
        total: 0,
        currentPage: 1,
        pageSize: 10,
      },
      tableData: [], //列表数据
      showLoading: false,
      showLoadingForm: false, //防重提交
      option: {
        title: "",
        titleSize: "h3",
        card: false,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: true, // 显示索引
        indexLabel: "序号", // 索引文字
        size: "small",
        selection: false, // 显示多选框
        page: true, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: "盘厂名称",
            prop: "name",
          },
          {
            label: "盘厂简称",
            prop: "abbr",
          },
          {
            label: "盘厂图片",
            prop: "image",
          },
          // {
          //   label: "合作模式",
          //   prop: "cooperate",
          // },
          {
            label: "联系人",
            prop: "contacts",
          },
          {
            label: "联系方式",
            prop: "phone",
          },

          {
            label: "所在地",
            prop: "address",
          },
          {
            label: "总授权数",
            prop: "totalAuthNum",
          },
          {
            label: "已用授权数",
            prop: "usedAuthNum",
          },
          {
            label: "首页推荐",
            prop: "isHome",
          },
          {
            label: "创建时间",
            prop: "createTime",
          },
        ],
      },

      dialogVisible: false,
      form: {
        image: [],
        equityIds: [],
      },
      // formOption: {
      //   submitBtn: false,
      //   submitText: "确定",
      //   emptyBtn: false,
      //   labelWidth: "120",
      //   row: true,
      //   column: [
      //     {
      //       label: "盘厂图片",
      //       prop: "image",
      //       type: "text",
      //       maxlength: 50,
      //       span: 20,
      //       rules: [
      //         {
      //           required: true,
      //           message: "请上传",
      //           trigger: "blur",
      //         },
      //         {
      //           validator: (rule, value, callback) => {
      //             if (value.length === 0) {
      //               callback(new Error("请上传"));
      //             } else {
      //               callback();
      //             }
      //           },
      //         },
      //       ],
      //     },
      //     {
      //       label: "盘厂名称",
      //       prop: "name",
      //       type: "text",
      //       maxlength: 20,
      //       placeholder: "请输入",
      //       span: 20,
      //       rules: [
      //         {
      //           required: true,
      //           message: "请输入盘厂名称",
      //           trigger: "blur",
      //         },
      //       ],
      //     },
      //     {
      //       label: "权益分类",
      //       prop: "equityIds",
      //       type: "text",
      //       maxlength: 20,
      //       placeholder: "请输入",
      //       span: 20,
      //       rules: [
      //         {
      //           required: true,
      //           message: "请选择",
      //           trigger: "blur",
      //         },
      //       ],
      //     },
      //     {
      //       label: "合作模式",
      //       prop: "cooperate",
      //       type: "text",
      //       maxlength: 20,
      //       placeholder: "请输入",
      //       span: 20,
      //       rules: [
      //         {
      //           required: true,
      //           message: "请选择",
      //           trigger: "blur",
      //         },
      //       ],
      //     },
      //     {
      //       label: "联系方式",
      //       prop: "phone",
      //       type: "text",
      //       maxlength: 20,
      //       placeholder: "请输入",
      //       span: 20,
      //       rules: [
      //         {
      //           required: true,
      //           message: "请输入联系方式",
      //           trigger: "blur",
      //         },
      //         ...mobilePhoneRule,
      //       ],
      //     },
      //     {
      //       label: "安调能力",
      //       prop: "power",
      //       type: "text",
      //       maxlength: 20,
      //       placeholder: "请输入",
      //       span: 20,
      //       rules: [
      //         {
      //           required: true,
      //           message: "请输入安调能力",
      //           trigger: "blur",
      //         },
      //       ],
      //     },
      //     {
      //       label: "所在地",
      //       prop: "address",
      //       type: "text",
      //       maxlength: 10,
      //       placeholder: "请输入",
      //       span: 20,
      //       rules: [
      //         {
      //           required: true,
      //           message: "请输入所在地",
      //           trigger: "blur",
      //         },
      //       ],
      //     },
      //   ],
      // },
      dialogPassWordVisible: false,
      dataUrl: "",
      classifyList: [], //分类下拉
    };
  },
  created() {
    this.getClassifyList();
  },
  methods: {
    //初始化
    onLoad() {
      const searchForm = {
        path: this.$route.path,
        searchData: this.searchData,
      };
      this.showLoading = true;
      factoryManageList({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...this.searchData,
      }).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      });
    },
    //图片上传
    upload(e) {
      if (Array.isArray(e)) {
        this.form.image = [...e];
      } else {
        this.form.image = [...this.form.image, e];
      }
    },
    // 添加
    // handleCreate() {
    //   this.form.image = [];
    //   this.isDetails = false;
    //   this.form.id = "";
    //   this.dialogVisible = true;
    // },
    handleCreate(type, id) {
      this.$refs.formRef.open(type, id);
    },
    // 查询
    handleSearch() {
      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 重置
    handleReset() {
      // console.log('重置')
      // localStorage.removeItem("searchForm");
      this.searchData = {
        title: "",
      };
      this.page.currentPage = 1;
      this.onLoad();
    },
    //编辑
    async handleEdit(row) {
      // console.log(row)
      this.isDetails = false;
      factoryManageDetail(row.id).then((res) => {
        this.form = {
          ...res.data,
          image: [res.data.image],
          equityIds: res.data.equityIds ? res.data.equityIds.split(",") : [],
        };
      });
      this.form.id = row.id;
      this.dialogVisible = true;
    },

    // 删除
    handleDelete(row) {
      this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        factoryManageDelete(row.id).then((res) => {
          if (res.code === 200) {
            this.$message.success("删除成功!");
            this.onLoad();
          }
        });
      });
    },
    // 重置form
    resetForm() {
      this.form = {
        id: "",
        type: "",
      };
    },

    // 关闭
    handleClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.resetForm();
      }, 500);
    },
    //输入
    salaryDianChange(e, t) {
      const value = e.target.value;
      this.form[t] = value;
    },
    // 提交
    submit(form) {
      this.$refs.form.$refs.form.validate((valid, done) => {
        if (valid) {
          if (this.showLoadingForm) {
            return;
          }
          this.showLoadingForm = true;
          // return
          if (form.id) {
            factoryManageUpdate({
              ...form,
              image: form.image[0],
              equityIds: form.equityIds.join(","),
            })
              .then((res) => {
                if (res.code === 200) {
                  this.$message.success("操作成功");
                  this.dialogVisible = false;
                  setTimeout(() => {
                    this.resetForm();
                  }, 500);
                  this.showLoadingForm = false;
                  this.onLoad();
                }
              })
              .catch(() => (this.showLoadingForm = false));
          } else {
            factoryManageAdd({
              ...form,
              image: form.image[0],
              equityIds: form.equityIds.join(","),
            })
              .then((res) => {
                if (res.code === 200) {
                  this.$message.success("操作成功");
                  this.dialogVisible = false;
                  setTimeout(() => {
                    this.resetForm();
                  }, 500);
                  this.showLoadingForm = false;
                  this.onLoad();
                }
              })
              .catch(() => (this.showLoadingForm = false));
          }
          // this.form.type = 1;
          // done()
        }
      });
    },
    // 查询
    handleSearch() {
      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad();
    },

    //权益分类
    getClassifyList() {
      factoryManageEquityClassifyList({}).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.classifyList = res.data.map((item, index) => {
            return {
              ...item,
              index,
            };
          });
        }
      });
    },
    //切换是否显示
    handleSwitch(e, id) {
      factoryManageHomeUpdate({
        id: id,
        isHome: e,
      }).then((res) => {
        if (res.code === 200) {
          this.$message.success("操作成功");
        }
        this.onLoad();
      });
    },
  },
};
</script>

<style scoped>
.copy {
  cursor: pointer;
  /* width: 60px; */
  margin-left: 15px;
  color: #66b1ff;
}
.tips {
  font-size: 20px;
  line-height: 1;
  margin-bottom: 30px;
}
</style>
